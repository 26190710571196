<template>
<div>
    <ol class="breadcrumb">
        <li class="breadcrumb-item">Taxes</li>
    </ol>

    <b-card no-body>
        <b-tabs card content-class="mt-3">
            <b-tab title="Individual Taxes" active>
                <h5>Individual Tax (These are individually charges taxes (GST, PST, etc...))</h5>
                <b-button class="float-right" variant="success" @click="addIndividualTax()">
                    <b-icon icon="plus-circle" /> Add Individual Tax
                </b-button>
                <br />
                <b-form v-for="(tax, index) in individual_taxes"
                    :key="index" inline>
                    <label class="m-2">Name:</label>
                    <b-form-input v-model="tax.name" placeholder="Name" />
                    <label class="m-2">Number:</label>
                    <b-form-input v-model="tax.number" placeholder="Number" />
                    <label class="m-2">Percent:</label>
                    <b-form-input v-model="tax.percentage" placeholder="Percent" />
                    <b-button variant="success" @click.prevent="saveIndividualTax(tax)">
                        <b-icon icon="check" />
                    </b-button>
                    <b-button variant="danger" @click.prevent="removeIndividualTax(tax, index)">
                        <b-icon icon="trash" />
                    </b-button>
                </b-form>
            </b-tab>
            <b-tab title="Applicable Taxes">
                <h5>Applicable Tax (These are chargeable to clients)</h5>
                <b-button class="float-right" variant="success" @click="addApplicableTax()">
                    <b-icon icon="plus-circle" /> Add Applicable Tax
                </b-button>
                <br />
                <b-form v-for="(tax, index) in applicable_taxes"
                    :key="index" inline>
                    <b-card>
                        <h5 v-if="isDefaultApplicableTax(tax)">Default</h5>
                        <label class="m-2">Name:</label>
                        <b-form-input v-model="tax.name" placeholder="Name" />
                        <b-button variant="success" @click.prevent="addTaxDropdown(tax)">
                            <b-icon icon="plus" />
                        </b-button>
                        <div v-for="(individual_tax_id, tax_index) in tax.individual_taxes" :key="tax_index">
                            <b-form-select v-model="tax.individual_taxes[tax_index]" :options="individualTaxOptions"/>
                            <b-button variant="danger" @click.prevent="removeTaxFromApplicableTax(tax, tax_index)">
                                <b-icon icon="dash" />
                            </b-button>
                        </div>
                        <b-button variant="success" @click.prevent="saveApplicableTax(tax)">
                            <b-icon icon="check" />
                        </b-button>
                        <b-button variant="danger" @click.prevent="removeApplicableTax(tax, index)">
                            <b-icon icon="trash" />
                        </b-button>
                        <b-button v-if="isEligibleToBeDefaultApplicableTax(tax)" variant="primary" @click.prevent="setDefaultApplicableTax(tax)">
                            Make Default
                        </b-button>
                    </b-card>
                </b-form>
            </b-tab>
        </b-tabs>
    </b-card>
</div>
</template>

<script>

import {
    listIndividualTaxes,
    listApplicableTaxes,
    createIndividualTax,
    createApplicableTax,
//    getIndividualTax,
//    getApplicableTax,
    updateIndividualTax,
    updateApplicableTax,
    deleteIndividualTax,
    deleteApplicableTax,
} from '@/api/taxes.api'
import crudMixin from '@/api/crud'
import { getSetting, updateSettings } from '@/api/settings.api'


export default {
    mixins: [crudMixin],
    data() {
        return {
            individual_taxes: [],
            applicable_taxes: [],
            default_tax: null
        }
    },
    computed: {
        individualTaxOptions() {
            return this.individual_taxes.filter((tax) => {
                return Object.hasOwn(tax, 'id')
            }).map((tax) => {
                return {
                    value: tax,
                    text: `${tax.name} -> ${tax.percentage}`
                }
            })
        }
    },
    mounted() {
        this.refreshIndividualTaxes()
        this.refreshApplicableTaxes()
        this.getDefaultApplicableTax()
    },
    methods: {
        isDefaultApplicableTax(tax) {
            return Object.hasOwn(tax, 'id') && tax.id == this.default_tax
        },
        isEligibleToBeDefaultApplicableTax(tax) {
            return Object.hasOwn(tax, 'id') && tax.id != this.default_tax
        },
        getDefaultApplicableTax() {
            this.call(
                getSetting("default_applicable_tax"),
                (res) => {
                    this.default_tax = res.data.setting.value
                }
            )
        },
        setDefaultApplicableTax(tax) {
            // Convert to FormData
            var formData = new FormData();
            formData.append("default_applicable_tax", tax.id)
            this.saveObject(
                updateSettings(formData),
                () => {
                    this.getDefaultApplicableTax()
                }
            )
        },
        addIndividualTax() {
            this.individual_taxes.push({
                "name": "",
                "number": "",
                "percentage": ""
            })
        },
        saveIndividualTax(individual_tax) {
            if (Object.hasOwn(individual_tax, 'id')) {
                var apiCall = updateIndividualTax(individual_tax.id, individual_tax)
            } else {
                apiCall = createIndividualTax(individual_tax)
            }
            this.saveObject(
                apiCall,
                () => {
                    this.refreshIndividualTaxes()
                }
            )
        },
        removeIndividualTax(individual_tax, index) {
            let tax = this.individual_taxes[index]
            if(individual_tax !== tax){
                return
            }
            if (Object.hasOwn(individual_tax, 'id')) {
                this.deleteObject(
                    deleteIndividualTax(tax.id),
                    () => {
                        this.refreshIndividualTaxes()
                    }
                )
            } else {
                this.individual_taxes.splice(index, 1)
            }
        },
        refreshIndividualTaxes() {
            this.call(
                listIndividualTaxes(),
                (res) => {
                    this.individual_taxes.splice(0)
                    this.individual_taxes.push(...res.data.individual_taxes)
                }
            )
        },
        refreshApplicableTaxes() {
            this.call(
                listApplicableTaxes(),
                (res) => {
                    this.applicable_taxes.splice(0)
                    this.applicable_taxes.push(...res.data.applicable_taxes)
                }
            )
        },
        addApplicableTax() {
            this.applicable_taxes.push({
                "name": "",
                "individual_taxes": []
            })
        },
        addTaxDropdown(tax) {
            tax["individual_taxes"].push({})
        },
        removeTaxFromApplicableTax(tax, tax_index) {
            tax.individual_taxes.splice(tax_index, 1)
        },
        removeApplicableTax(applicable_tax, index) {
            let tax = this.applicable_taxes[index]
            if(applicable_tax !== tax){
                return
            }
            if (Object.hasOwn(applicable_tax, 'id')) {
                this.deleteObject(
                    deleteApplicableTax(tax.id),
                    () => {
                        this.refreshApplicableTaxes()
                    }
                )
            } else {
                this.applicable_taxes.splice(index, 1)
            }
        },
        saveApplicableTax(applicable_tax) {
            if (Object.hasOwn(applicable_tax, 'id')) {
                var apiCall = updateApplicableTax(applicable_tax.id, applicable_tax)
            } else {
                apiCall = createApplicableTax(applicable_tax)
            }
            this.saveObject(
                apiCall,
                () => {
                    this.refreshApplicableTaxes()
                }
            )
        }
    },
}


</script>
